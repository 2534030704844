/*
оранж - #FF6C48
желтый - #ECBB69
зеленый - #47C780
голубой - #3B8DFF
фон #081624
*/

:root {
    --background: #fff;

    --primary: #202427;
    --primary-text: #888d8e;
    --primary-element: #363b3d;
    --primary-element-border: #363b3d;

    --secondary: #fff;
    --secondary-text: #000;
    --secondary-element: #f2f7fa;
    --secondary-element-border: #f2f7fa;
    --secondary-icon: #9b9fb3;

    --element-radius: 15px;

    --white: #fff;
    --border: 1px solid #e6ebf4;
    --placeholder: #818c99;
    --radius: 8px;
    --block: #0000;
    --header-background: #F7F8FC;
    --item-hover: #F7F8FF;
    --item-active: #edeffc;
    --menu-color: #fff;
    --scrollbar-bg: #01020366;
    --color: #081624;
    --button-hover: #0000;
    --open: #f3f3f3;
    --header-color: #fff;
    --text: #081624;
    --menu-active: #3f4049;
    --menu-bg: #363740;
    --menu-color: #fff;
    --menu-hover: #3a3b44;
    --load-item: #e0e0e0;
    --border-list: 1px solid #afafaf57;
    --size: calc(100vw / 160);

    --width-content: calc(100vw / 4);
    --height-footer: calc(100vw / 6);

    --size-slot: calc(100vw / 12);
    --font-slot: calc(100vw / 20);





    --width-prize: calc(100vw / 3);
    --width-info: calc(100vw / 5);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.revers {
   -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

::-webkit-scrollbar {
   width: 0px;
   height: 0px;
}

::spelling-error {
  text-decoration: wavy red;
}

#root {
   display: flex;
   max-width: 800px;
   width: 100%;
   padding: 20px;
   margin: auto;
   flex-direction: column;
   height: 100dvh;
}

header {
   display: flex;
   margin: 10px 0;
   height: 50px;
   align-items: center;
}

header .--name{
  flex: 1;
  font-size: 25px;
  padding: 20px;
}

header .--date{
  font-size: 20px;
}


main{


}


.app_label{
   width: 100px;
   height: 100px;
   margin: 20px auto;
}

.item{
   display: flex;
   align-items: center;
   margin-bottom: 10px;
}

.item .check{
    flex: 0 100px;
    text-align: right;
}

.name {
    font-size: 15px;
    flex: 1;
    padding-left: 20px;
}

.prize {
    flex: 1;
    text-align: right;
}

.slots {
	  overflow: auto;
	  color: #eee;
	  font-family: sans-serif;
	  text-align: center;
    display: flex;
    justify-content: center;
    height: var(--size-slot);
}

.slots:nth-child(1){

    background: #FF6C48;
}
/*
оранж - #FF6C48
желтый - #ECBB69
зеленый - #47C780
голубой - #3B8DFF
*/

.slots span:nth-child(1){  background: #FF6C48; }
.slots span:nth-child(2){  background: #47C780; }
.slots span:nth-child(3){  background: #3B8DFF; }

.slots span {
	  color: #fff;
    cursor: default;
	  border-radius: 20%;
    float: left;
  	font-size: var(--font-slot);
	  font-weight: bold;
	  width: var(--size-slot);
    height: var(--size-slot);
	  line-height: var(--size-slot);
    margin-left: 5px;
     -webkit-transition: all 0.67s ease;
	  transition: all 0.67s ease;
}



.slots span.spin {
	transform: rotateX(720deg);
}

.slots span:first-child {
	margin-left: 0;
}

.avatar {
    background: #fff;
    width: 50px;
    height: 50px;
    margin: auto;
    border-radius: 25%;
}

@keyframes anim-rotate {	0% {transform: rotate(0);	}	100% {transform: rotate(360deg);}}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  font-size: 14px;
  background: var(--background);
  color: var(--text);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

section{
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #2196f3;
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  list-style: none;
  padding-top: 50px;
  overflow: scroll;
}
a {
  text-decoration: none;
  color: #3b4144;
}

p a {
  text-decoration: underline;
  color: #3b4144;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}
h1 {
  line-height: 35px;
  padding-bottom: 22px;
}
h1 span {
  display: block;
}
h2 {
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
}

h3 {
  font-size: 15px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
h4 {
  font-size: 20px;
  margin-bottom: 20px;
}
h5 {
  font-size: 13px;
  line-height: 25px;
}
p {
  font-size: 13px;
  line-height: 23px;
}

.icon-load {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.icon-load canvas {
    border: 1px dotted #0000001f;
    border-radius: 15px;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    margin: -150px -120px;
}

.load-text{
   text-align: center;
}

.error-text{
   text-align: center;
}

.icon-load .track_cale {
    -webkit-appearance: none;
    background: #d7dcdf;
    border: 0;
    border-radius: 5px;
    display: block;
    height: 10px;
    margin: 15px auto 10px;
    outline: none;
    padding: 0;
    width: 200px;
}

.icon-load .track_cale::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #7ac1fa;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    -webkit-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out;
    width: 20px;
}

.icon-load .edit__markup{
   background: #000;
   position: absolute;
   width: 300px;
   height: 300px;
   left: 30px;
   z-index: 1;
   background: linear-gradient(90deg,#0000 48%,#303030 50%,#0000 52%),linear-gradient(0deg,#0000 48%,#303030 50%,#0000 52%);
   background-size: calc(300px / 8) calc(300px / 8);
   opacity: .2;
}
.icon-load .edit__markup.top{
    opacity: 1;
    z-index: 0;
}

.icon-load canvas.opacity-true{
    opacity: .3;
}

.image-toolbar{
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    z-index: 1;
}

.image-toolbar i, .image-toolbar label{
    background: var(--secondary-element);
    border: 1px solid var(--secondary-element-border);
    color: var(--secondary-icon);
    padding: 7px;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.image-list {
   width: 300px;
   margin: auto;
   margin-top: 20px;
   display: grid;
   grid-template-columns: 145px 145px;
   grid-column-gap: 10px;
   grid-row-gap: 10px;
}

.image-item {
   position:relative;
}

.image-closed {
   position: absolute;
   width: 30px;
   height: 30px;
   background: #000;
   border-radius: 50%;
   right: 5px;
   top: 5px;
   cursor: pointer;
   display: none;

}

.image-item:hover .image-closed{
   display: block;
}


.image-list img {
    width: 145px;
    border: 1px solid var(--secondary-element-border);
    border-radius: 10px;
    cursor: pointer;
}
.item .user{
   color: #999;
   width: fit-content;
}

.item .user:hover{
   cursor: pointer;
   color: #3b96ff;
}

.image-list .add {
  width: 145px;
  border: 1px solid var(--secondary-element-border);
  border-radius: 10px;
  cursor: pointer;
  height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #cecece;
}
.image-list .active {
   border: 3px solid #9e9e9e;
}

.price_groups{
  display: flex;
  justify-content: space-between;
}

.check-link{
   cursor: pointer;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   font-size: 18px;
}

.check-link i{
   margin-left: 10px;
   color: #48d080;
}

.price_groups dl{
  flex: 1;
  padding-right: 10px;
}

.price_groups dl:last-child{
  padding-right: 0;
}

/*
width: 313px;

  height: 361px;
*/


[role="presentation"]{

}

/*
.icon-load canvas._300x260 {
    margin: -100px -150px;
}*/
